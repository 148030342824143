import { Dialog, Transition } from '@headlessui/react';
import Link from 'next/link';
import { Fragment } from 'react';
import { t } from '../../../generated/i18n';
import { topNavigation } from '../../../generated/navigation';
import closeSvg from '../../../img/close.svg';
import dropdownSvg from '../../../img/dropdown.svg';
import { SearchInput } from '../../filter/search-input';
import { IS_DEFAULT_CHANNEL } from '../../../lib/config';

const MenuItemLink = ({ url, label, onClick, subLinks = undefined }) => {
  if (subLinks && subLinks.length > 0) {
    return (
      <>
        {subLinks.map((m, index) => (
          <MenuItemLink
            key={index}
            url={m.link.href}
            onClick={onClick}
            label={m.text}
          />
        ))}
      </>
    );
  }

  return (
    <Link
      href={url}
      onClick={onClick}
      className='flex items-center border-b border-0.3 py-3
      text-base font-bold uppercase focus:outline-none active:underline'
    >
      <span className='flex-grow'>{label}</span>
      <img
        alt=''
        src={dropdownSvg}
        className={`h-[5px] -rotate-90 transform justify-self-end text-right`}
      />
    </Link>
  );
};

export const Menu = ({ toggleShowMenu, showMenu }) => {
  return (
    <>
      <Transition.Root show={showMenu} as={Fragment}>
        <Dialog as='div' className='relative z-50' onClose={toggleShowMenu}>
          <div className='fixed inset-0' />

          <div className='fixed inset-0 overflow-hidden'>
            <div className='absolute inset-0 overflow-hidden'>
              <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full'>
                <Transition.Child
                  as={Fragment}
                  enter='transform transition ease-in-out duration-300'
                  enterFrom='translate-y-0 translate-x-full'
                  enterTo='translate-y-0 translate-x-0'
                  leave='transform transition ease-in-out duration-300'
                  leaveFrom='translate-y-0 translate-x-0'
                  leaveTo='translate-y-0 translate-x-full'
                >
                  <Dialog.Panel className='pointer-events-auto w-screen sm:max-w-md'>
                    <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
                      {/* FIXME: The whole reverse thing is a hack so the search input is not autofocused */}
                      <div className='flex flex-col-reverse px-6 py-4'>
                        <div>

                          {topNavigation.map((el, key) => (
                            <MenuItemLink
                              key={key}
                              onClick={toggleShowMenu}
                              url={el.link?.href}
                              label={el.text}
                              subLinks={el.subLinks}
                            />
                          ))}

                            <MenuItemLink
                              onClick={toggleShowMenu}
                              url='https://gastro.edvinweine.ch/'
                              label={t('nav-b2b-login')}
                            />
                            
                        </div>

                        <div className='flex border-b border-0.3 py-10'>
                          <div className='relative flex-grow'>
                            <div className='pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-5'>
                              <svg
                                width='16'
                                height='16'
                                viewBox='0 0 16 16'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M15.8855 15.0501L11.1535 10.308C12.1876 9.06227 12.7013 7.46643 12.588 5.8526C12.4747 4.23845 11.7431 2.7302 10.545 1.64037C9.34683 0.550866 7.77486 -0.0358908 6.15476 0.00170086C4.53466 0.0396194 2.99182 0.698945 1.84574 1.84304C0.699987 2.98713 0.0397049 4.52807 0.00173133 6.14581C-0.0362422 7.76324 0.551693 9.33326 1.64245 10.5297C2.73354 11.7261 4.2443 12.4566 5.86079 12.5697C7.47729 12.6828 9.07512 12.17 10.3227 11.1373L15.0547 15.8624C15.202 15.9886 15.4036 16.0314 15.5896 15.9765C15.7758 15.9216 15.9215 15.7761 15.9765 15.5902C16.0315 15.4042 15.9886 15.2031 15.8622 15.0557L15.8852 15.0498L15.8855 15.0501ZM1.15665 6.3037C1.15665 4.9383 1.69974 3.62881 2.66676 2.66319C3.63377 1.6979 4.94517 1.15527 6.31255 1.15527C7.67992 1.15527 8.99132 1.69758 9.95834 2.66319C10.9254 3.62881 11.4684 4.9383 11.4684 6.3037C11.4684 7.66909 10.9254 8.97859 9.95834 9.94421C8.99132 10.9098 7.67992 11.4521 6.31255 11.4521C4.94517 11.4521 3.63377 10.9098 2.66676 9.94421C1.69974 8.97859 1.15665 7.66909 1.15665 6.3037Z'
                                  fill='#3E2D2A'
                                  fillOpacity='0.5'
                                />
                              </svg>
                            </div>

                            <SearchInput
                              onSearch={toggleShowMenu}
                              className='relative ml-2 mr-3'
                              inputClassName='rounded-full border-0.6 block w-full py-1.5 pl-10 text-gray-900 border placeholder:text-gray-400 focus:outline-none'
                            />
                          </div>
                          <button
                            onClick={toggleShowMenu}
                            className='mr-2 focus:outline-none'
                            aria-label={t('close')}
                          >
                            <img
                              alt=''
                              src={closeSvg}
                              className='w-24px block'
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
