export const Container = ({
  children,
  className = '',
  wide = true,
  rightAlign = false,
  ...props
}) => (
  <div
    className={`${wide ? 'max-w-6xl' : 'max-w-2xl'} ${
      rightAlign ? 'ml-auto mr-0' : 'mx-auto'
    } px-6 6xl:px-0 ${className}`}
    {...props}
  >
    {children}
  </div>
);

export const maxContainerClassNames = 'max-w-8xl mx-auto px-4 sm:px-6 lg:px-8';

export const MaxContainer = ({ children, className = '' }) => (
  <div className={`${className} ${maxContainerClassNames}`}>{children}</div>
);
