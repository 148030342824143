import Link from 'next/link';
import arrow_next from '../../img/arrow-next.svg';

export const ReadMoreLink = ({
  href = '',
  label,
  className = '',
  onClick = null,
  backArrow = false,
  prefetch = true,
}) => {
  const classes = `${className} cursor-pointer uppercase font-bold flex items-center content-center link`;

  if (href && !onClick) {
    const props = !prefetch ? { prefetch } : undefined;
    return (
      <Link href={href} {...props} className={classes}>
        <span className='mr-2'>{label}</span>
        <img src={arrow_next} alt='' />
      </Link>
    );
  }

  return (
    <div className={classes} onClick={onClick} role='link'>
      {backArrow && (
        <img className='mr-2 rotate-180 transform' src={arrow_next} alt='' />
      )}
      <span>{label}</span>
      {!backArrow && <img className='ml-2' src={arrow_next} alt='' />}
    </div>
  );
};
