import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { t } from '../../generated/i18n';
import { IS_DEFAULT_CHANNEL } from '../../lib/config';

const Detail = () => {
  if (!IS_DEFAULT_CHANNEL) {
    return <div className='py-10'>
    <h2 className='text-base'>GRATISLIEFERUNG AB CHF 500.-</h2>
    Bestellungen ab CHF 500.- werden portofrei geliefert. Unter dem Bestellbetrag von CHF 500.- werden CHF 30.- berechnet.
    <h2 className='mt-4 text-base md:mt-8'>LIEFERZEIT</h2>
    Ab Bestelleingang erhalten Sie die Lieferung in zwei Arbeitstagen.
    <h2 className='mt-4 text-base md:mt-8'>EXPRESS DELIVERY</h2>
    Bestelleingänge bis 15.00 Uhr (max. 12 Fl.) werden am Folgetag ausgeliefert. Für diese Expresslieferungen werden CHF 23.- verrechnet. 

    <h2 className='mt-4 text-base md:mt-8'>KONTAKT</h2>
    Tel: <a href="tel:+41628380030" className='link font-bold'>+41 62 838 00 30</a><br/>
    Email: <a href="mailto:bestellungen@edvinweine.ch" className='link font-bold'>bestellungen@edvinweine.ch</a><br/>
    <h2 className='mt-4 text-base md:mt-8'>Ab Schweizer Lager</h2>
    Die Weinpakete werden von unserem Team im Lager der Weinkellereien Aarau
    liebevoll verpackt und verschickt.
    <h2 className='mt-4 text-base md:mt-8'>Google Rating 5.0 ★</h2>
    Wir freuen uns über viele zufriedene Kundinnen und Kunden und hoffen auch
    dir eine Freude zu machen.
    </div>
  }
  
  return (
  <div className='py-10'>
    {/* TODO translate */}
    <h2 className='text-base'>Next Day Delivery</h2>
    Alle Bestellungen, die von Montag bis Donnerstag bis 16:00 Uhr abgeschlossen
    sind, werden am Folgetag geliefert. Bestellungen bis Freitag um 16:00 Uhr
    erfolgen am Montag, Bestellungen von Freitag nach 16 Uhr bis Sonntag
    erfolgen am Dienstag.
    <h2 className='mt-4 text-base md:mt-8'>Zahlungsarten</h2>
    Wir bieten eine Vielzahl an Zahlungsmöglichkeiten an, damit alle den
    passenden Weg finden.
    <h2 className='mt-4 text-base md:mt-8'>Auch auf Rechnung</h2>
    Wir bieten eine Vielzahl an Zahlungsmöglichkeiten an, damit alle den
    passenden Weg finden. Bei einem Erstkauf via Rechnung gibt es eine Limite
    von CHF 500.- und danach eine Limite von CHF 1’000.-
    <img
      src='/static/payments-small-invoice.svg'
      loading='lazy'
      className='mt-5'
    />
    <h2 className='mt-4 text-base md:mt-8'>Gratislieferung ab CHF 180.-</h2>
    Bestellungen ab CHF 180.- werden portofrei geliefert. Bis zu diesem Betrag
    werden CHF 9.90 verrechnet.
    <h2 className='mt-4 text-base md:mt-8'>Ab Schweizer Lager</h2>
    Die Weinpakete werden von unserem Team im Lager der Weinkellereien Aarau
    liebevoll verpackt und verschickt.
    <h2 className='mt-4 text-base md:mt-8'>Google Rating 5.0 ★</h2>
    Wir freuen uns über viele zufriedene Kundinnen und Kunden und hoffen auch
    dir eine Freude zu machen.
  </div>
)};

const BaseBanner = () => {
  const [open, setOpen] = useState(false);

  const onClick = () => {
    setOpen(true);
  };

  return (
    <>
      <div
        role='alert'
        className={`block cursor-pointer bg-green text-center text-white`}
        onClick={onClick}
      >
        <div className='mx-auto flex max-w-7xl items-center justify-center pb-[2px] pt-[4px] leading-5 md:pb-0'>
          <p className='banner pl-2'>{t('banner_delivery')}</p>

          <button
            role='button'
            className='cursor-pointer px-3 py-1 text-xl font-bold'
            aria-label={t('readMore')}
          >
            +
          </button>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' className='relative z-50' onClose={setOpen}>
          <div className='fixed inset-0 overflow-hidden'>
            <div className='absolute inset-0 overflow-hidden'>
              <div className='pointer-events-none fixed inset-y-0 left-0 flex max-w-full'>
                <Transition.Child
                  as={Fragment}
                  enter='transform transition ease-in-out duration-300 sm:duration-500'
                  enterFrom='-translate-x-full'
                  enterTo='-translate-x-0'
                  leave='transform transition ease-in-out duration-300 sm:duration-500'
                  leaveFrom='-translate-x-0'
                  leaveTo='-translate-x-full'
                >
                  <Dialog.Panel className='pointer-events-auto relative w-screen md:max-w-1/2'>
                    <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
                      <button
                        type='button'
                        className='absolute right-5 top-5 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none md:right-10 md:top-10'
                        onClick={() => setOpen(false)}
                      >
                        <span className='sr-only'>Close panel</span>
                        <svg
                          width='21'
                          height='21'
                          viewBox='0 0 21 21'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M1 19.8687L19.8687 1'
                            stroke='#231F20'
                            strokeWidth='1.5'
                          />
                          <path
                            d='M19.8687 19.8687L1 1'
                            stroke='#231F20'
                            strokeWidth='1.5'
                          />
                        </svg>
                      </button>
                      <div className='md: mx-auto flex max-w-sm flex-1 flex-col justify-center px-4 py-10 md:px-0 md:py-0'>
                        <Detail />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export const Banner = React.memo(BaseBanner);
