import { useContext, useEffect, useState } from 'react';
import { WishlistContext } from './wishlist-context';

export const WishlistIcon = ({ children = null, className = '' }) => {
  const { totalItemsCount } = useContext(WishlistContext);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(totalItemsCount);
  });

  return (
    <div className={`relative flex items-center justify-center ${className}`}>
      <svg
        width='14'
        height='18'
        viewBox='0 0 14 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M1 2C1 1.44772 1.44772 1 2 1H12C12.5523 1 13 1.44772 13 2V15.9325C13 16.3564 12.5056 16.588 12.1799 16.3166L7 12L1.82009 16.3166C1.49443 16.588 1 16.3564 1 15.9325V2Z'
          stroke='black'
          strokeWidth='1.25'
          strokeLinejoin='round'
        />
      </svg>

      {count > 0 && (
        <div className='absolute -right-3 -top-2 flex h-5 w-5 items-center justify-center rounded-full bg-black text-sm text-white'>
          <div className='mt-px'>{count}</div>
        </div>
      )}
      {children && <>{children}</>}
    </div>
  );
};
