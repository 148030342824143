
import nav_wissen_svg from '../img/nav/wissen.svg';
import nav_trophy_svg from '../img/nav/trophy.svg';
import nav_trophy_line_svg from '../img/nav/trophy-line.svg';
import nav_shop_svg from '../img/nav/shop.svg';
import nav_search_svg from '../img/nav/search.svg';
import nav_return_svg from '../img/nav/return.svg';
import nav_profile_svg from '../img/nav/profile.svg';
import nav_payment_svg from '../img/nav/payment.svg';
import nav_info_svg from '../img/nav/info.svg';
import nav_feedback_svg from '../img/nav/feedback.svg';
import nav_fav_svg from '../img/nav/fav.svg';
import nav_faq_svg from '../img/nav/faq.svg';
import nav_events_svg from '../img/nav/events.svg';
import nav_delivery_svg from '../img/nav/delivery.svg';
import nav_clock_svg from '../img/nav/clock.svg';
import nav_cart_svg from '../img/nav/cart.svg';
import nav_bottle_svg from '../img/nav/bottle.svg';
import social_spotify_svg from '../img/social/spotify.svg';
import social_insta_svg from '../img/social/insta.svg';
import social_fb_svg from '../img/social/fb.svg'

const svgObject = {
  nav_wissen: nav_wissen_svg,
  nav_trophy: nav_trophy_svg,
  nav_trophy_line: nav_trophy_line_svg,
  nav_shop: nav_shop_svg,
  nav_search: nav_search_svg,
  nav_return: nav_return_svg,
  nav_profile: nav_profile_svg,
  nav_payment: nav_payment_svg,
  nav_info: nav_info_svg,
  nav_feedback: nav_feedback_svg,
  nav_fav: nav_fav_svg,
  nav_faq: nav_faq_svg,
  nav_events: nav_events_svg,
  nav_delivery: nav_delivery_svg,
  nav_clock: nav_clock_svg,
  nav_cart: nav_cart_svg,
  nav_bottle: nav_bottle_svg,
  social_spotify: social_spotify_svg,
  social_insta: social_insta_svg,
  social_fb: social_fb_svg
};

export const getSvg = (key) => {
    return svgObject[key] ?? nav_fav_svg;
};